<script setup lang="ts">
import {AisHighlight} from "vue-instantsearch/vue3/es";

const props = defineProps<{
    activeFilter: string | undefined;
    items: any;
    name: string;
    refine: any;
    firstFilter: boolean;
    useInstantSearch: boolean;
}>();

const emits = defineEmits<{
    (e: "filter-selected", name: string): string;
}>();

const activeFilters = inject('activeFiltersState', {}) as Ref<{}>;

const triggerRefine = (item) => {
    if (props.useInstantSearch) {
        props.refine(item.value);
        activeFilters.value[props.name] = refinedCount;
    } else {
        props.refine(props.name, item.value);
    }
}

const refinedCount = computed(() => {
    return props.items.filter((item: any) => {
        return item.isRefined;
    }).length;
});
</script>

<template>
    <div
        class="accordion filter arrow-bold items-center text-brand-dark py-3 border border-brand-gray border-t-solid w-full bg-white flex justify-end"
        v-show="props.items.length > 0"
        :class="{'expanded text-brand-orange': props.name === props.activeFilter, 'border-t-none!': props.firstFilter }"
        @click="emits('filter-selected', props.name)"
    >
        <span class="pointer-events-none text-[18px] font-medium flex-auto">
            {{ props.name }}
        </span>
        <span
            v-if="refinedCount"
            class="bg-brand-orange h-[25px] w-[25px] rounded-full text-white text-[16px] flex items-center justify-center mr-[25px] "
        >
            {{ refinedCount }}
        </span>
    </div>
    <div class="panel bg-white pb-4" :class="{'hidden': props.name !== props.activeFilter}">
        <div class="flex flex-col">
            <label v-for="item in props.items" :for="item.value" class="flex items-center mt-[15px]">
                <input class="bevco-checkbox border-black!" type="checkbox" :name="item.value" :id="item.value" @change.prevent="triggerRefine(item);" :checked="item.isRefined" />
                <ais-highlight v-if="props.useInstantSearch" attribute="item" class="ml-1 text-[18px] font-medium" :hit="item"/>
                <span v-else class="ais-Highlight ml-1 text-[18px] font-medium">{{item.label}}</span>
                <span class="text-gray-400 text-xs ml-2">({{ item.count.toLocaleString() }})</span>
            </label>
        </div>
    </div>
</template>
